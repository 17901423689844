// src/context/LangContext.js
import React, { createContext, useState, useEffect } from "react";
import i18n from "../i18n"; // Adjust the path according to your project structure
import { useLocation, useNavigate } from "react-router-dom";

export const LangContext = createContext();
const supportedLngs = ["en", "az", "ru"];

export const LangProvider = ({ children }) => {
  const [lang, setLang] = useState(localStorage.getItem("language") || "az");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const changeLanguage = (newLang) => {
    setLang(newLang);
    localStorage.setItem("language", newLang);
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);

    const languageRegex = new RegExp(`^/(?:${supportedLngs.join("|")})(/|$)`);
    const cleanedPathname = pathname
      .replace(languageRegex, "/")
      .replace(/\/+$/, "");
    navigate(`${lang}${cleanedPathname}`);
  }, [lang, navigate, pathname]);

  return (
    <LangContext.Provider value={{ lang, changeLanguage }}>
      {children}
    </LangContext.Provider>
  );
};

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { enTranslation } from "./translate/en/translation.js";
import { azTranslation } from "./translate/az/translation.js";
import { ruTranslation } from "./translate/ru/translation.js";

const storedLanguage = localStorage.getItem("language") || "az";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "az", "ru"],
    resources: {
      en: enTranslation,
      az: azTranslation,
      ru: ruTranslation,
    },
    lng: storedLanguage,
    fallbackLng: "az",
    detection: {
      order: ["cookie", "localStorage", "htmlTag", "path", "subdomain"],
      caches: ["cookie"],
    },
  });

export default i18n;

import React from "react";
import ReactLoading from "react-loading";
import "../Loading/style.css";

const Loading = () => {
  return (
    <div>
      <div className="loading-screen">
        <ReactLoading type="bubbles" color="#ffcc29" height={100} width={120} />
      </div>
    </div>
  );
};

export default Loading;

import React, { useContext, useEffect } from "react";
import "./serviceSide.scss";
import Service from "../../../components/Services/Service";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../../api/customApi";
import Spinner from "../../../components/Spinner";
import { LangContext } from "../../../context/LangContext";
import { useTranslation } from "react-i18next";


const ServiceSide = () => {
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();
  const location = useLocation();
  const { inputValue, setInputValue } = useOutletContext();
  const lng = localStorage.getItem("language") || 'az'

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search") || "";

  useEffect(() => {
    setInputValue(searchQuery);
  }, [searchQuery]);

  const { data: categories, isLoading: categoriesLoading } = useQuery({
    queryKey: ["services", lang, searchQuery],
    queryFn: () => {
      const url = searchQuery ? `/services?search=${searchQuery}` : "/services";
      return getApi(url);
    },
  });

  const setService = (az, en, ru) => {
    localStorage.setItem(
      "minor_service_name",
      JSON.stringify({ az: az, en: en, ru: ru })
    );
  };

  return (
    <div className="service_side">
      <h1 className="service_side_head_label">{t("services.title")}</h1>
      {categoriesLoading && <Spinner />}
      {!categoriesLoading && categories && categories.length === 0 && (
        <p className="no_results_message">{t("services.noResults")}</p>
      )}
      <div className="service_side_container">
        {
          categories && categories.map((service) => {
            const slug = service?.slug?.[lang];
            return (
              <Link
                to={`/${lng}/services/${slug}`}
                className="service_side_container_link"
                // onClick={() =>
                //   setService(service.title?.az, service.title?.en, service.title?.ru)
                // }
                key={service.id}
              >
                <Service label={service.title?.[lang] || ""} />
              </Link>
            )
          })}
      </div>
    </div>
  );
};

export default ServiceSide;

import React, { createRef, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/minor-group-logo.png";
import LogoWhite from "../../assets/images/minor-main-logo.png";
import { IoSearch } from "react-icons/io5";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { IoMenu } from "react-icons/io5";
import { Drawer, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../api/customApi";
import { LangContext } from "../../context/LangContext";
import "../Header/style.css";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import downArrow from "../../assets/icons/down-arrow.svg";

const Header = ({ activeSlide, inputValue, setInputValue }) => {
  const scrollableNodeRef = createRef();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [openModal, setOpenModal] = useState(false);
  const [color, setColor] = useState(false);
  const { lang, changeLanguage } = useContext(LangContext);
  const { t, i18n } = useTranslation();

  const location = useLocation();
  
  // const supportedLanguages = ["az", "ru", "en"]; // Desteklenen diller
  // const currentLanguage = location.pathname.split('/')[1]; // URL'deki dil kısmını alır
  // const isMainPage = supportedLanguages.includes(currentLanguage); // Desteklenen dillerden birine eşitse true
  

  // const isMainPage = location.pathname === "/az";
  let validPaths = ["/az", "/ru", "/en"]; 
  let isMainPage = validPaths.includes(location.pathname); 

  let isFirstSlide = activeSlide === 0;
  let footerColor = isFirstSlide ? "white" : "black";

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const changeColor = () => {
    if (window.scrollY >= 40) {
      isFirstSlide=!isFirstSlide;
    } else {
      
    }
  };

  const languageItems = [
    {
      key: "az",
      label: (
        <a
          href="#"
          onClick={(e) => {
            changeLanguage("az");
          }}
        >
          AZ
        </a>
      ),
    },
    {
      key: "en",
      label: (
        <a
          href="#"
          onClick={(e) => {
            changeLanguage("en");
          }}
        >
          EN
        </a>
      ),
    },
    {
      key: "ru",
      label: (
        <a
          href="#"
          onClick={(e) => {
            changeLanguage("ru");
          }}
        >
          RU
        </a>
      ),
    },
  ];

  window.addEventListener("scroll", changeColor);
  const { data: services } = useQuery({
    queryKey: ["servicesItems", lang],
    queryFn: () => getApi("/services"),
  });
  const { data: legislations } = useQuery({
    queryKey: ["legislations", lang],
    queryFn: () => getApi("/legislations"),
  });

  const serviceItems = Array.isArray(services)
    ? services?.map((service) => ({
      key: service?.id,
      label: (
        <a href={`/${lang}/services/${service?.slug?.[lang]}`}>{service?.title?.[lang]}</a>
      ),
    }))
    : [];

  const abouItems = [
    {
      key: "aboutItem",
      label: <a href={`/prologue`}>Önsöz</a>,
    },
  ];

  const legislationItems = Array.isArray(legislations)
    ? legislations?.map((legislation) => ({
      key: legislation?.id,
      label: (
        <a href={legislation?.url} target="_blank" rel="noopener noreferrer">
          {legislation?.title?.[lang]}
        </a>
      ),
    }))
    : [];

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    onClose();
  }, [location]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const navigate = useNavigate();

  const handleSearch = () => {
    if (inputValue.trim()) {
      navigate(`/services?search=${inputValue}`);
      setOpenModal(false);
    }
  };

  return (
    <div className={color ? "header-wrapper headerBg" : "header-wrapper"}>
      <div className="container-header">
        <div className="header-logo">
          <NavLink to="">
            {isFirstSlide && isMainPage ? (
              <img
              src={LogoWhite}
                alt="White Logo"
                className="header-logo-img"
              />
            ) : (
              <img
                 src={Logo}
                alt="Black Logo"
                className="header-logo-img"
                loading="lazy"
              />
            )}
          </NavLink>
        </div>
        <div className="header-links">
  <Dropdown
    menu={{
      items: abouItems,
    }}
    trigger={["hover"]}
  >
    <NavLink
      to="/about"
      className={(goPage) => (goPage.isActive ? "active" : "")}
      style={{ color: isFirstSlide && isMainPage ? "white" : "black" }}  
    >
      {t("header.about")}
      <Space>
        <DownOutlined
          style={{
            color:isFirstSlide &&  isMainPage ? "white" : "black",  
          }}
        />
      </Space>
    </NavLink>
  </Dropdown>

  <Dropdown
    menu={{
      items: serviceItems,
    }}
    overlayStyle={{ height: 500, overflow: "auto" }}
    overlayClassName="centered-dropdown"
    trigger={["hover"]}
  >
    <NavLink
      to={inputValue.trim() ? `/services?search=${inputValue}` : "/services"}
      className={(goPage) => (goPage.isActive ? "active" : "")}
      style={{ color:isFirstSlide &&  isMainPage ? "white" : "black" }}  
    >
      {t("header.services")}
      <Space>
        <DownOutlined
          style={{
            color: isFirstSlide && isMainPage ? "white" : "black", 
          }}
        />
      </Space>
    </NavLink>
  </Dropdown>

  <Dropdown
    menu={{
      items: legislationItems,
    }}
    overlayStyle={{ height: 500, overflow: "auto" }}
    overlayClassName="l-centered-dropdown"
    trigger={["hover"]}
  >
    <a
      style={{
        color:isFirstSlide && isMainPage ? "white" : "black",  
        cursor: "pointer",
      }}
    >
      {t("header.legislation")}
      <Space>
        <DownOutlined
          style={{
            color:isFirstSlide && isMainPage ? "white" : "black",  
          }}
        />
      </Space>
    </a>
  </Dropdown>

  <NavLink
    to="/certification"
    className={(goPage) => (goPage.isActive ? "active" : "")}
    style={{ color: isFirstSlide && isMainPage ? "white" : "black" }} 
  >
    {t("header.certificates")}
  </NavLink>

  <NavLink
    to="/faq"
    className={(goPage) => (goPage.isActive ? "active" : "")}
    style={{ color:isFirstSlide &&  isMainPage ? "white" : "black" }}  
  >
    {t("header.faq")}
  </NavLink>

  <NavLink
    to="/contact"
    className={(goPage) => (goPage.isActive ? "active" : "")}
    style={{ color:isFirstSlide && isMainPage ? "white" : "black" }} 
  >
    {t("header.contact")}
  </NavLink>
</div>

        <div className="language-search-content">
          <div className="language-wrapper">
            <ul className="languages">
              {["az", "en", "ru"].map((lng) => (
                <li key={lng}>
                  <a
                    className={lang === lng ? "active-lang" : ""}
                    onClick={() => {
                      changeLanguage(lng);
                      window.location.reload(); 
                    }}
                    style={
                      isFirstSlide &&   !isMainPage ? { color: "white" } : { color: footerColor }
                    }
                  >
                    {lng.toUpperCase()}
                  </a>
                </li>
              ))}
            </ul>
            <div className="dropdown-language">
              <Dropdown
                menu={{
                  items: languageItems,
                }}
                overlayStyle={{ height: "auto", overflow: "hidden" }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space
                    className="active-language"
                    style={
                      isFirstSlide &&  !isMainPage ? { color: "black" } : { color: footerColor }
                    }
                  >
                    {lang.toUpperCase()}
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
          <div
            className="search"
            style={ !isMainPage ? { color: "black" } : { color: footerColor }}
          >
            <IoSearch onClick={() => setOpenModal(true)} />
          </div>
          <Modal
            open={openModal}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
            width={1000}
            closable={true}
          >
            <div className="input-wrapper">
              {/* <NavLink to="/">
                <img src={Logo} alt="Logo" style={{ width: "180px" }} />
              </NavLink> */}
              <input
                type="text"
                placeholder={t("header.search")}
                className="modal-input"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <IoSearch className="modal-search-icon" onClick={handleSearch} />
            </div>
          </Modal>
          <IoMenu
            className="menu-icon"
            onClick={showDrawer}
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          />
          {/* <Space>
            <Radio.Group value={placement} onChange={onChange}></Radio.Group>
          </Space> */}
          <Drawer
            title={
              <NavLink to="/">
                <img src={LogoWhite} alt="Logo" style={{ width: "180px" }} />
              </NavLink>
            }
            placement={placement}
            closable={true}
            onClose={onClose}
            open={open}
            key={placement}
          >
            <div className="header-links-menu">
              <NavLink
                to="/about"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.about")}
              </NavLink>
              <NavLink
                to="/services"
                className={(goPage) => (goPage.isActive ? "active" : "")}
                onClick={showDrawer}
              >
                {t("header.services")}
              </NavLink>
              <a
                onClick={toggleDropdown}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={downArrow}
                  alt="down arrow icon"
                  style={{
                    transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
                    transition: "transform .3s",
                  }}
                />
                {t("header.legislation")}
              </a>
              <SimpleBar
                style={{
                  maxHeight: "370px",
                  width: "90%",
                }}
                scrollableNodeProps={{ ref: scrollableNodeRef }}
              >
                <div
                  className="dropdown-content"
                  style={{
                    display: isOpen ? "block" : "none",
                    backgroundColor: "transparent",
                    direction: "rtl",
                  }}
                >
                  <ul
                    className="menu-list"
                    style={{ listStyleType: "none", padding: 0, margin: 0 }}
                  >
                    {legislationItems?.map((item, index) => (
                      <li
                        key={item.key || index}
                        style={{ padding: "0px 0", cursor: "pointer" }}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              </SimpleBar>
              <NavLink
                to="/certification"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.certificates")}
              </NavLink>
              <NavLink
                to="/faq"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.faq")}
              </NavLink>
              <NavLink
                to="/contact"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.contact")}
              </NavLink>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Header;

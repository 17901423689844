import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import { getApi } from "../../api/customApi";
import logo from "../../assets/images/minor-group-logo.png";
import Spinner from "../../components/Spinner";
import Company from "./companies";
import Event from "./event";
import Letter from "./letter";
import "./about.scss";
import { useTranslation } from "react-i18next";
import EventContent from "../../components/EventContent";
import { LangContext } from "../../context/LangContext";

const About = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LangContext);

  const { data: companies, isLoading: companiesLoading } = useQuery({
    queryKey: ["companies", lang],
    queryFn: () => getApi("/companies"),
  });

  const { data: letters, isLoading: lettersLoading } = useQuery({
    queryKey: ["letters", lang],
    queryFn: () => getApi("/letters"),
  });

  const { data: events, isLoading: eventsLoading } = useQuery({
    queryKey: ["events", lang],
    queryFn: () => getApi("/events"),
  });

  const { data: about, isLoading: aboutLoading } = useQuery({
    queryKey: ["about", lang],
    queryFn: () => getApi("/about"),
  });

  return (
    <div className="about-section">
      <div className="bg-header"></div>
      <div className="about">
        <div className="container">
          <img
            className="logo mb-hidden"
            src={logo}
            alt="logo"
            loading="lazy"
          />
          <div className="content">
            <div className="center-logo">
              <img
                className="logo mb-block"
                src={logo}
                alt="logo"
                loading="lazy"
              />
            </div>
            <div className="about-content">
              {aboutLoading ? (
                <Spinner />
              ) : (
                <EventContent content={about?.content?.[lang] || ""} />
              )}
            </div>
            <p className="text bold">{t("about.title2")}</p>
            <div className="companies">
              {companiesLoading ? (
                <Spinner />
              ) : (
                companies?.map((company) => (
                  <Company
                    key={company?.id || ""}
                    content={company?.content?.[lang] || ""}
                    url={company?.url || ""}
                    title={company?.title?.[lang] || ""}
                    image={
                      Object.entries(company?.image)?.[0]?.[1]?.original_url ||
                      ""
                    }
                  />
                ))
              )}
            </div>
            <p className="text bold">{t("about.title3")}</p>
            <div className="letters">
              {lettersLoading ? (
                <Spinner />
              ) : (
                letters?.map((letter) => (
                  <Letter
                    key={letter?.id}
                    title={letter?.title?.[lang]}
                    image={
                      Object.entries(letter?.image)?.[0]?.[1]?.original_url
                    }
                  />
                ))
              )}
            </div>
            <p className="text bold">{t("about.title4")}</p>
            <div className="events">
              {eventsLoading ? (
                <Spinner />
              ) : (
                events?.map((event) => (
                  <Event
                    key={event?.id}
                    content={event?.content?.[lang]}
                    date={event?.date}
                    images={event?.images}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import "./certificate.scss";
import arrow from '../../../assets/images/png/arrow.png'

const Certificate = ({ code, label }) => {
  return (
    <div className="certificate">
      <div className="certificate_head">
        <h1 className="certificate_head_label">{code}</h1>
        <img className="certificate_head_arrow" width={34} height={34} src={arrow} alt="arrow icon" loading="lazy"/>
      </div>
      <p className="certificate_name">{label}</p>
    </div>
  );
};

export default Certificate;

import React from "react";
import "./service.scss";
import arrow from '../../../assets/images/png/arrow.png'

const Service = ({ label }) => {
  return (
    <div className="service">
      <div className="service_head">
        <h1 className="service_head_label">{label}</h1>
        <img className="service_head_arrow" width={34} height={34} src={arrow} alt="arrow icon" loading="lazy" />
      </div>
    </div>
  );
};

export default Service;
import About from "../pages/About";
import Certification from "../pages/Certification";
import Certificates from "../pages/Certification/certificates";
import CertificateDetail from "../pages/Certification/detail";
import Contact from "../pages/Contact";
import ContactForm from "../pages/Contact/ContactForm";
import FAQ from "../pages/FAQ";
import Home from "../pages/Home";
import Prologue from "../pages/Prologue";
import Root from "../pages/Root";
import Services from "../pages/Services";
import ServiceDetail from "../pages/Services/detail";
import ServiceSide from "../pages/Services/serviceSide";

export const ROOT = [
  {
    path: "",
    element: <Root />,
    children: [
      {
        path: "/:lng",
        element: <Home />,
      },
      {
        path: "/:lng/about",
        element: <About />,
      },
      {
        path: "/:lng/prologue",
        element: <Prologue />,
      },
      {
        path: "/:lng/faq",
        element: <FAQ />,
      },
      {
        path: "/:lng/certification",
        element: <Certification />,
        children: [
          {
            path: "/:lng/certification",
            element: <Certificates />,
          },
          {
            path: "/:lng/certification/:slug",
            element: <CertificateDetail />,
          },
        ],
      },
      {
        path: "/:lng/contact",
        element: <Contact />,
      },
      {
        path: "/:lng/contact-form",
        element: <ContactForm />,
      },
      {
        path: "/:lng/services",
        element: <Services />,
        children: [
          {
            path: "/:lng/services",
            element: <ServiceSide />,
          },
          {
            path: "/:lng/services/:slug",
            element: <ServiceDetail />,
          },
        ],
      },
    ],
  },
];

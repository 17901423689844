import React from "react";
import "./certification.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg from '../../assets/images/png/certification-bg.jpg'
import long from '../../assets/images/png/long-arrow.png';

const Certification = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const isCertificationPage = location.pathname === "/certification";

  return (
    <>
      <img className="certification_bg" src={bg} alt="certification background" loading="lazy"/>
      <div className="certification">
        {!isCertificationPage && (
          <Link to="/certification" className="certification_back_button">
            <img
              className=""
              width={41}
              height={41}
              src={long}
              alt="arrow icon"
            />
            {t("services.backBtn")}
          </Link>
        )}
        <Outlet />
      </div>
    </>
  );
};

export default Certification;

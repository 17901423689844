import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../api/customApi";
import Accordion from "../../components/Accordion";
import Spinner from "../../components/Spinner";
import "./faq.scss";
import { useTranslation } from "react-i18next";
import { LangContext } from "../../context/LangContext";

const Answers = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LangContext);
  const { data: faqs, isLoading: faqsLoading } = useQuery({
    queryKey: ["faqs",lang],
    queryFn: () => getApi("/faqs"),
  });

  return (
    <div className="faq">
      <div className="bg"></div>
      <div className="bg-container">
        <div className="content-container">
          <h1>{t("faq.title")}</h1>
          <div className="content">
            <div className="accordion">
              {faqsLoading ? (
                <Spinner />
              ) : (
                faqs?.map((faq) => (
                  <Accordion
                    key={faq?.id}
                    title={faq?.question?.[lang]}
                    content={faq?.answer?.[lang]}
                  />
                ))
              )}
            </div>
            <div className="btn-box">
              <Link to="/contact">
                <button className="btn">{t("faq.btn")}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Answers;
